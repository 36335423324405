import React, { useState, useEffect } from 'react';
import { Modal, Table, Typography, Tabs, Spin, Button, message } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, CopyOutlined } from '@ant-design/icons';
import ReactAce from 'react-ace';
import axios from 'axios';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';

const { Text } = Typography;
const { TabPane } = Tabs;

function HistoryModal({ visible, onClose, memberName, API_BASE_URL, selectedEnv, onLoadRequestFromHistory }) {
  const [historyItems, setHistoryItems] = useState([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);

  // Local state for showing/hiding credentials
  const [showApiKey, setShowApiKey] = useState(false);
  const [showBearer, setShowBearer] = useState(false);

  useEffect(() => {
    if (visible && memberName && selectedEnv) {
      fetchHistory();
    } else {
      // Reset when closed
      setHistoryItems([]);
      setSelectedHistoryItem(null);
      setSelectedRowKey(null);
      setShowApiKey(false);
      setShowBearer(false);
    }
  }, [visible, memberName, selectedEnv]);

  const fetchHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/history`, {
        params: { memberName }
      });
      // Filter out requests that do not match the selected environment
      const filteredItems = response.data.filter(item => item.selectedEnv === selectedEnv);
      setHistoryItems(filteredItems);
    } catch (error) {
      console.error('Failed to fetch history:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (value) => new Date(value).toLocaleString(),
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      
    },
    {
      title: 'API',
      dataIndex: 'selectedApi',
      key: 'selectedApi',
      sorter: (a, b) => a.selectedApi.localeCompare(b.selectedApi),
    },
    {
      title: 'Group',
      dataIndex: 'selectedGroup',
      key: 'selectedGroup',
      sorter: (a, b) => (a.selectedGroup || '').localeCompare(b.selectedGroup || ''),
      render: (text) => text || <Text type="secondary">N/A</Text>,
    },
  ];

  const onRowClick = (record) => {
    setSelectedHistoryItem(record);
    setSelectedRowKey(record._id); 
    setShowApiKey(false);
    setShowBearer(false);
  };

  const maskString = (str) => {
    if (!str) return '';
    return '*'.repeat(Math.max(str.length, 6));
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy).then(
      () => { message.success('Copied to clipboard'); },
      () => { message.error('Failed to copy'); }
    );
  };

  const requestBodyString = selectedHistoryItem && selectedHistoryItem.requestData
    ? JSON.stringify(selectedHistoryItem.requestData, null, 2)
    : '{}';

  // Check presence of credentials
  const hasApiKey = selectedHistoryItem?.apiKey && selectedHistoryItem.apiKey.trim() !== '';
  const hasBearer = selectedHistoryItem?.bearerToken && selectedHistoryItem.bearerToken.trim() !== '';

  return (
    <Modal
      title="Request History"
      visible={visible}
      onCancel={onClose}
      width="90%"
      footer={null}
      bodyStyle={{ padding: '16px' }}
    >
      <div style={{ display: 'flex', gap: '16px', height: '70vh' }}>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {loading ? (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table
              dataSource={historyItems}
              columns={columns}
              rowKey={(record) => record._id}
              size="small"
              onRow={(record) => ({
                onClick: () => onRowClick(record),
                style: { cursor: 'pointer' },
              })}
              rowClassName={(record) =>
                record._id === selectedRowKey ? 'ant-table-row-selected' : ''
              }
              pagination={{ pageSize: 10 }}
            />
          )}
        </div>

        {/* Details Panel */}
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            border: '1px solid #f0f0f0',
            borderRadius: '4px',
            padding: '16px',
            background: '#fff'
          }}
        >
          {selectedHistoryItem ? (
            <>
              {/* Metadata Section */}
      {/* Metadata Section */}
<div
  style={{
    marginBottom: '16px',
    padding: '12px',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    background: '#fafafa'
  }}
>
  <Text strong style={{ fontSize: '16px', display:'block', marginBottom:'12px' }}>
    Request Metadata
  </Text>

  {hasApiKey && (
    <div style={{ display: 'flex', alignItems: 'center', gap:'8px', marginBottom:'8px' }}>
      <Text strong style={{flexShrink:0}}>API Key:</Text>
      <div style={{ display:'flex', alignItems:'center', gap:'8px', minWidth:0, flex:1 }}>
        <div style={{ flex:'1 1 auto', overflowX:'auto', whiteSpace:'nowrap' }}>
          <Text code style={{display:'inline-block'}}>
            {showApiKey ? selectedHistoryItem.apiKey : maskString(selectedHistoryItem.apiKey)}
          </Text>
        </div>
        <Button
          type="text"
          icon={showApiKey ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => setShowApiKey(!showApiKey)}
        />
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={() => handleCopy(selectedHistoryItem.apiKey)}
        />
      </div>
    </div>
  )}

  {hasBearer && (
    <div style={{ display: 'flex', alignItems: 'center', gap:'8px', marginBottom:'8px' }}>
      <Text strong style={{flexShrink:0}}>Bearer:</Text>
      <div style={{ display:'flex', alignItems:'center', gap:'8px', minWidth:0, flex:1 }}>
        <div style={{ flex:'1 1 auto', overflowX:'auto', whiteSpace:'nowrap' }}>
          <Text code style={{display:'inline-block'}}>
            {showBearer ? selectedHistoryItem.bearerToken : maskString(selectedHistoryItem.bearerToken)}
          </Text>
        </div>
        <Button
          type="text"
          icon={showBearer ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          onClick={() => setShowBearer(!showBearer)}
        />
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={() => handleCopy(selectedHistoryItem.bearerToken)}
        />
      </div>
    </div>
  )}

  {selectedHistoryItem.selectedGroup && (
    <div style={{ display:'flex', alignItems:'center', marginBottom:'8px' }}>
      <Text strong style={{ width:'90px', flexShrink:0 }}>Group:</Text>
      <div style={{ flex:'1', overflowX:'auto', whiteSpace:'nowrap' }}>
        <Text code style={{display:'inline-block'}}>{selectedHistoryItem.selectedGroup}</Text>
      </div>
    </div>
  )}

  {selectedHistoryItem.selectedApi && (
    <div style={{ display:'flex', alignItems:'center', marginBottom:'8px' }}>
      <Text strong style={{ width:'90px', flexShrink:0 }}>API:</Text>
      <div style={{ flex:'1', overflowX:'auto', whiteSpace:'nowrap' }}>
        <Text code style={{display:'inline-block'}}>{selectedHistoryItem.selectedApi}</Text>
      </div>
    </div>
  )}
</div>


              <Tabs defaultActiveKey="requestData" style={{ height: '100%' }}>
              <TabPane tab="Request Data" key="requestData">
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
    <Text type="secondary">
      Here is the JSON data that was sent during this request.
    </Text>
  
    <Button
      type="link"
      icon={<CopyOutlined />}
      onClick={() => handleCopy(requestBodyString)}
    >
      Copy
    </Button>
  </div>
  
  <ReactAce
    mode="json"
    theme="cobalt"
    value={requestBodyString}
    readOnly
    fontSize={14}
    width="100%"
    height="400px"
    setOptions={{ useWorker: false }}
    style={{
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
    }}
    key={selectedRowKey} // Force re-render if needed
  />

<div style={{ textAlign: 'right', padding: '16px' }}>
  <Button
    type="primary"
    onClick={() => onLoadRequestFromHistory(selectedHistoryItem)}
  >
    Load & Edit
  </Button>
</div>

</TabPane>

              </Tabs>
            </>
          ) : (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
              <Text type="secondary">Select a record from the table to view details</Text>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default HistoryModal;

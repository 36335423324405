import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Checkbox,
  DatePicker,
  Upload,
  Divider,
  Tooltip,
  Space,
  Typography,
  message,
  Modal,
  Switch,
} from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  InfoCircleOutlined,
  ExpandOutlined, CompressOutlined, CopyOutlined,
} from '@ant-design/icons';
import ReactAce from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';
import 'ace-builds/src-noconflict/theme-github';

const { Option } = Select;
const { Text } = Typography;

function ApiForm({ selectedApi, initialRequestParams, onRunRequest, onPreviewRequest }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [jsonModal, setJsonModal] = useState({
    visible: false,
    fieldName: [],
    dataType: '',
  });
  const [jsonValue, setJsonValue] = useState('{}');
  const [jsonFieldLabel, setJsonFieldLabel] = useState('');
  const [jsonEditorKey, setJsonEditorKey] = useState(0);
  const [useRawBody, setUseRawBody] = useState(false); // New state for toggle
  const [rawBodyValue, setRawBodyValue] = useState('{}'); // State to store raw JSON input
  const [isEditorExpanded, setIsEditorExpanded] = useState(false);
  

  

 
  useEffect(() => {
    if (initialRequestParams && selectedApi && selectedApi.parameters) {
      const formValues = { include: {}, values: {}, attributes: {} };
      const paramTypes = ['query', 'body', 'path', 'header', 'form-data'];
      paramTypes.forEach(pt => {
        formValues.include[pt] = {};
        formValues.values[pt] = {};
      });

      // Gather known parameter names by type
      const queryParamNames = selectedApi.parameters
        .filter(p => p.parameterType === 'query')
        .map(p => p.name);

      const pathParamNames = selectedApi.parameters
        .filter(p => p.parameterType === 'path')
        .map(p => p.name);

      const headerParamNames = selectedApi.parameters
        .filter(p => p.parameterType === 'header')
        .map(p => p.name);

      const bodyParamNames = selectedApi.parameters
        .filter(p => p.parameterType === 'body')
        .map(p => p.name);

      const formDataParamNames = selectedApi.parameters
        .filter(p => p.parameterType === 'form-data')
        .map(p => p.name);

      const knownAttributes = selectedApi.attributes?.map(a => a.name) || [];

      const { query = {}, path = {}, headers = {}, body = {}, formData = {}, attributes = [] } = initialRequestParams;

      let rawBodyMode = false;

      // Helper to map known parameters of a given type
      const mapParams = (paramType, knownNames, sourceObj) => {
        knownNames.forEach(paramName => {
          if (sourceObj && Object.prototype.hasOwnProperty.call(sourceObj, paramName)) {
            // param found
            formValues.include[paramType][paramName] = true;
            formValues.values[paramType][paramName] = sourceObj[paramName];
          } else {
            // param not found
            formValues.include[paramType][paramName] = false;
          }
        });

        // Check for unknown keys in this param type section
        if (sourceObj && typeof sourceObj === 'object') {
          Object.keys(sourceObj).forEach(k => {
            if (!knownNames.includes(k)) {
              // Unknown key found -> raw body mode
              rawBodyMode = true;
            }
          });
        }
      };

      // Map query, path, headers, form-data
      mapParams('query', queryParamNames, query);
      mapParams('path', pathParamNames, path);
      mapParams('header', headerParamNames, headers);
      mapParams('form-data', formDataParamNames, formData);

      // Handle attributes - map known attributes
      if (selectedApi.attributes && selectedApi.attributes.length > 0) {
        selectedApi.attributes.forEach((attr, index) => {
          formValues.attributes[`attr_${index}`] = attributes.includes(attr.name);
        });

        // Check for unknown attributes
        attributes.forEach(attrName => {
          if (!knownAttributes.includes(attrName)) {
            // Unknown attribute -> raw body mode
            rawBodyMode = true;
          }
        });
      }

      // Handle body parameters
      bodyParamNames.forEach(paramName => {
        if (body && Object.prototype.hasOwnProperty.call(body, paramName)) {
          formValues.include.body[paramName] = true;
          formValues.values.body[paramName] = body[paramName];
        } else {
          formValues.include.body[paramName] = false;
        }
      });

      // Check if there are unknown body keys
      if (body && typeof body === 'object') {
        Object.keys(body).forEach(k => {
          if (!bodyParamNames.includes(k)) {
            // Unknown body key -> raw body mode
            rawBodyMode = true;
          }
        });
      }

      // Prepare raw body content
      let rawBodyContent = '{}';
      if (body && Object.keys(body).length > 0) {
        rawBodyContent = JSON.stringify(body, null, 2);
      }

      setUseRawBody(rawBodyMode);
      setRawBodyValue(rawBodyContent);
      form.setFieldsValue(formValues);
    }
  }, [initialRequestParams, selectedApi, form]);

  // **Remove the conflicting useEffect**
  // This useEffect was resetting the form and raw body state whenever selectedApi changes,
  // which interferes with the initial mapping from initialRequestParams.
  // We need to remove or modify it to prevent overriding the mapped values.

  // useEffect(() => {
  //   form.resetFields();
  //   setUseRawBody(false);
  //   setRawBodyValue('{}');
  // }, [selectedApi]);

  // **Optional:** If you need to reset the form when a new API is selected manually (not from history),
  // you can add a condition to check if initialRequestParams is not present.

  useEffect(() => {
    if (!initialRequestParams && selectedApi) {
      form.resetFields();
      setUseRawBody(false);
      setRawBodyValue('{}');
    }
  }, [selectedApi, initialRequestParams, form]);

  const handleCopy = () => {
    navigator.clipboard.writeText(rawBodyValue).then(
      () => {
        message.success('Copied to clipboard');
      },
      () => {
        message.error('Failed to copy to clipboard');
      }
    );
  };
  
  const toggleExpand = () => {
    setIsEditorExpanded((prevState) => !prevState);
  };
  





  const setNestedValue = (obj, path, value) => {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      const key = path[i];
      if (i === path.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) current[key] = {};
        current = current[key];
      }
    }
  };

  const getNestedValue = (obj, path) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  const handleRunRequest = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      const requestData = await buildRequestData(values, selectedApi.parameters, selectedApi);
      await onRunRequest(requestData);
    } catch (error) {
      console.error('Error during form validation or API call:', error);
      message.error('Failed to run the request. Please check the input fields.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewRequest = async () => {
    try {
      const values = await form.validateFields();
      const requestData = await buildRequestData(values, selectedApi.parameters, selectedApi);
      await onPreviewRequest(requestData);
    } catch (error) {
      console.error('Error during form validation or preview:', error);
      message.error('Failed to preview the request. Please check the input fields.');
    }
  };

  const buildRequestData = async (values, parameters, selectedApi) => {
    const requestData = {};

    // Helper function to filter out null and empty values
    const filterEmptyValues = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => filterEmptyValues(item));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = typeof value === 'object' ? filterEmptyValues(value) : value;
          }
          return acc;
        }, {});
      }
      return obj;
    };

    // Initialize sections
    const queryParams = {};
    const pathParams = {};
    const headerParams = {};
    const bodyParams = {};
    const formDataParams = {};

    // Handle parameters
    parameters.forEach((param) => {
      const fieldName = [param.parameterType, param.name];
      const includeParam = getNestedValue(values, ['include', ...fieldName]);
      if (includeParam) {
        let value = getNestedValue(values, ['values', ...fieldName]);

        if (value !== undefined && value !== null && value !== '') {
          // Handle date conversion
          if (param.dataType === 'date' && value) {
            value = value.format('YYYY-MM-DD');
          }

          const paramType = param.parameterType;
          if (paramType === 'query') {
            setNestedValue(queryParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'path') {
            setNestedValue(pathParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'header') {
            setNestedValue(headerParams, param.fieldPath || [param.name], value);
          } else if (paramType === 'body') {
            if (!useRawBody) {
              setNestedValue(bodyParams, param.fieldPath || [param.name], value);
            }
          } else if (paramType === 'form-data') {
            if (param.formDataFields) {
              param.formDataFields.forEach((field) => {
                const includeField = getNestedValue(values, [
                  'include',
                  ...fieldName,
                  field.fieldName,
                ]);

                if (includeField) {
                  const fieldValue = getNestedValue(values, [
                    'values',
                    ...fieldName,
                    field.fieldName,
                  ]);

                  if (field.fieldType === 'file') {
                    formDataParams[field.fieldName] = fieldValue?.[0]?.originFileObj;
                  } else {
                    formDataParams[field.fieldName] = fieldValue;
                  }
                }
              });
            } else {
              formDataParams[param.name] = value;
            }
          }
        }
      }
    });

    // Apply filter to remove empty or null values
    const filteredQueryParams = filterEmptyValues(queryParams);
    const filteredPathParams = filterEmptyValues(pathParams);
    const filteredHeaderParams = filterEmptyValues(headerParams);
    const filteredFormDataParams = filterEmptyValues(formDataParams);

    // Assign filtered sections to requestData if they have values
    if (Object.keys(filteredQueryParams).length > 0) {
      requestData.query = filteredQueryParams;
    }
    if (Object.keys(filteredPathParams).length > 0) {
      requestData.path = filteredPathParams;
    }
    if (Object.keys(filteredHeaderParams).length > 0) {
      requestData.headers = filteredHeaderParams;
    }
    if (Object.keys(filteredFormDataParams).length > 0) {
      requestData.formData = filteredFormDataParams;
    }

    // Handle body parameters
    if (useRawBody) {
      try {
        const parsedBody = JSON.parse(rawBodyValue);
        requestData.body = parsedBody;
      } catch (error) {
        message.error('Invalid JSON in raw body. Please correct it and try again.');
        throw error;
      }
    } else {
      const filteredBodyParams = filterEmptyValues(bodyParams);
      if (Object.keys(filteredBodyParams).length > 0) {
        requestData.body = filteredBodyParams;
      }
    }

    // Handle attributes
    if (!useRawBody && selectedApi?.attributes && selectedApi.attributes.length > 0) {
      const selectedAttributes = [];
      selectedApi.attributes.forEach((attr, index) => {
        const isSelected = values.attributes?.[`attr_${index}`];
        if (isSelected) {
          selectedAttributes.push(attr.name);
        }
      });
      if (selectedAttributes.length > 0) {
        requestData.attributes = selectedAttributes;
      }
    }

    return requestData;
  };

  const renderField = (param, fieldPath, isNested = false) => {
    const dataType = param.dataType || param.fieldType;
    const fieldName = [...fieldPath];
    const label = (
      <span>
        {param.name || param.fieldName}{' '}
        {param.description && (
          <Tooltip
            title={param.description}
            placement="right"
            overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
            color="#f5f5f5"
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}{' '}
        <Text type="secondary">({dataType})</Text>
      </span>
    );

    const uniqueKey = fieldName.join('-');

    if (!isNested) {
      // For optional parameters, wrap the field in a Form.Item with a Checkbox
      return (
        <div key={uniqueKey} style={{ marginBottom: '16px' }}>
          <Space align="baseline">
            <Form.Item
              name={['include', ...fieldName]}
              valuePropName="checked"
              initialValue={false}
              noStyle
            >
              <Checkbox />
            </Form.Item>
            <label>{label}</label>
          </Space>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                getNestedValue(prevValues, ['include', ...fieldName]) !==
                getNestedValue(curValues, ['include', ...fieldName])
              );
            }}
            noStyle
          >
            {() => {
              const includeParam = getNestedValue(form.getFieldsValue(), [
                'include',
                ...fieldName,
              ]);
              if (includeParam) {
                return (
                  <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                    {renderInputField(param, fieldName)}
                  </div>
                );
              }
              return null;
            }}
          </Form.Item>
        </div>
      );
    } else {
      // For nested fields, render the input field directly
      return (
        <div key={uniqueKey} style={{ marginBottom: '16px', marginLeft: '24px' }}>
          <label>{label}</label>
          {renderInputField(param, fieldName)}
        </div>
      );
    }
  };

  const renderInputField = (param, fieldName) => {
    const dataType = param.dataType || param.fieldType;
    const rules = [];

    switch (dataType) {
      case 'string':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            rules={rules}
            key={['values', ...fieldName].join('-')}
          >
            <Input placeholder={`Enter ${param.name || param.fieldName}`} />
          </Form.Item>
        );
      case 'number':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            key={['values', ...fieldName].join('-')}
            rules={[
              {
                validator: (_, value) => {
                  if (value === null || value === undefined || value === '') {
                    return Promise.resolve();
                  }
                  if (typeof value === 'number') {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`${param.name || param.fieldName} must be a number`)
                  );
                },
              },
            ]}
          >
            <InputNumber
              placeholder={`Enter ${param.name || param.fieldName}`}
              style={{ width: '100%' }}
              onChange={(value) => {
                const newValue =
                  value === '' || value === null || value === undefined ? null : value;
                form.setFields([
                  {
                    name: ['values', ...fieldName],
                    value: newValue,
                  },
                ]);
              }}
            />
          </Form.Item>
        );
      case 'enum':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            key={['values', ...fieldName].join('-')}
          >
            <Select placeholder={`Select ${param.name || param.fieldName}`}>
              {param.enumValues.map((enumValue) => (
                <Option key={enumValue} value={enumValue}>
                  {enumValue}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case 'boolean':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            key={['values', ...fieldName].join('-')}
          >
            <Select placeholder={`Select ${param.name || param.fieldName}`}>
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            key={['values', ...fieldName].join('-')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder={`Select ${param.name || param.fieldName}`}
            />
          </Form.Item>
        );
      case 'array':
        return renderArrayField(param, fieldName);
      case 'object':
        return renderObjectField(param, fieldName);
      default:
        return null;
    }
  };

  const renderArrayField = (param, fieldName) => {
    const arrayItemType = param.arrayItemType;
    const schema = param.objectSchema || [];

    // Check if it's an array of enums
    if (arrayItemType === 'enum' && param.enumValues && param.enumValues.length > 0) {
      return (
        <Form.Item
          name={['values', ...fieldName]}
          key={['values', ...fieldName].join('-')}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder={`Select ${param.name || param.fieldName}`}
            tokenSeparators={[',']}
          >
            {param.enumValues.map((enumValue) => (
              <Option key={enumValue} value={enumValue}>
                {enumValue}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    // If it's an array of objects or arrays without a schema, use ACE editor
    if (
      (arrayItemType === 'object' || arrayItemType === 'array') &&
      schema.length === 0
    ) {
      // Get the current value from the form
      const currentValue =
        getNestedValue(form.getFieldsValue(), ['values', ...fieldName]) || '';
      const stringValue =
        typeof currentValue === 'object' ? JSON.stringify(currentValue, null, 2) : '';

      return (
        <Form.Item key={['values', ...fieldName].join('-')}>
          <Button
            onClick={() =>
              openJsonModal(fieldName, param.name || param.fieldName, 'array')
            }
            style={{ marginBottom: '10px' }}
          >
            {`Edit ${param.name || param.fieldName} (JSON)`}
          </Button>
          {stringValue && (
            <ReactAce
              key={jsonEditorKey}
              mode="json"
              theme="github"
              name={`readonly_json_${fieldName.join('_')}`}
              value={stringValue}
              readOnly
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine={false}
              setOptions={{
                showLineNumbers: true,
                tabSize: 2,
                useWorker: false,
              }}
              style={{
                width: '90%',
                height: '200px',
                borderRadius: '8px',
                border: '1px solid #d9d9d9',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            />
          )}
        </Form.Item>
      );
    }

    // Render a simple tags input for arrays of basic types (e.g., string, number)
    if (arrayItemType !== 'object' && arrayItemType !== 'array') {
      return (
        <Form.Item
          name={['values', ...fieldName]}
          key={['values', ...fieldName].join('-')}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder={`Enter ${param.name || param.fieldName}`}
            tokenSeparators={[',']}
          />
        </Form.Item>
      );
    }

    // For array of objects with a schema, render each field according to its data type
    return (
      <Form.List name={['values', ...fieldName]}>
        {(fields, { add, remove }) => (
          <div>
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  border: '1px solid #ccc',
                  padding: '10px',
                  marginBottom: '10px',
                  marginLeft: '24px',
                }}
              >
                <Space
                  align="baseline"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Text>
                    {param.name || param.fieldName} [{name + 1}]
                  </Text>
                  <Button
                    type="text"
                    onClick={() => remove(name)}
                    icon={<MinusCircleOutlined />}
                  />
                </Space>

                {/* Render child fields based on objectSchema */}
                {schema.length > 0
                  ? schema.map((childParam, childIndex) => {
                      const childName =
                        childParam.fieldName || `Field ${childIndex + 1}`;
                      const dataType =
                        childParam.dataType || childParam.fieldType || 'string';
                      const label = (
                        <span>
                          {childName}{' '}
                          {childParam.description && (
                            <Tooltip
                              title={childParam.description}
                              placement="right"
                              overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                              color="#f5f5f5"
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          )}
                          <Text type="secondary"> ({dataType})</Text>
                        </span>
                      );

                      // Define validation rules based on data type
                      const rules = [];

                      if (dataType === 'number') {
                        rules.push({
                          validator: (_, value) => {
                            if (
                              value === '' ||
                              value === undefined ||
                              value === null
                            ) {
                              return Promise.resolve();
                            }
                            if (typeof value === 'number') {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(`${childName} must be a number`)
                            );
                          },
                        });
                      } else if (dataType === 'date') {
                        rules.push({
                          type: 'object',
                          message: `${childName} must be a valid date`,
                        });
                      } else if (dataType === 'boolean') {
                        rules.push({
                          type: 'boolean',
                          message: `${childName} must be true or false`,
                        });
                      }

                      // Choose appropriate input component based on data type
                      return (
                        <Form.Item
                          {...restField}
                          name={[name, childParam.name || childName]}
                          label={label}
                          key={`${key}-${childName}`}
                          rules={rules}
                        >
                          {dataType === 'number' ? (
                            <InputNumber
                              placeholder={`Enter ${childName}`}
                              style={{ width: '100%' }}
                            />
                          ) : dataType === 'date' ? (
                            <DatePicker
                              style={{ width: '100%' }}
                              format="YYYY-MM-DD"
                              placeholder={`Select ${childName}`}
                            />
                          ) : dataType === 'boolean' ? (
                            <Select placeholder={`Select ${childName}`}>
                              <Option value={true}>True</Option>
                              <Option value={false}>False</Option>
                            </Select>
                          ) : dataType === 'enum' ? (
                            <Select placeholder={`Select ${childName}`}>
                              {childParam.enumValues?.map((enumValue) => (
                                <Option key={enumValue} value={enumValue}>
                                  {enumValue}
                                </Option>
                              ))}
                            </Select>
                          ) : dataType === 'array' ? (
                            renderArrayField(
                              childParam,
                              [...fieldName, name.toString(), childParam.name || childName]
                            )
                          ) : dataType === 'object' ? (
                            renderObjectField(
                              childParam,
                              [...fieldName, name.toString(), childParam.name || childName]
                            )
                          ) : (
                            <Input placeholder={`Enter ${childName}`} />
                          )}
                        </Form.Item>
                      );
                    })
                  : null}
              </div>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add {param.name}
            </Button>
          </div>
        )}
      </Form.List>
    );
  };

  const renderObjectField = (param, fieldName) => {
    const schema = param.objectSchema || [];

    if (schema.length === 0) {
      // Get the current value from the form
      const currentValue =
        getNestedValue(form.getFieldsValue(), ['values', ...fieldName]) || '';
      const stringValue =
        typeof currentValue === 'object' ? JSON.stringify(currentValue, null, 2) : '';

      // Register the field with a hidden Form.Item
      return (
        <Form.Item noStyle key={['values', ...fieldName].join('-')}>
          <Form.Item name={['values', ...fieldName]} style={{ display: 'none' }}>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              getNestedValue(prevValues, ['values', ...fieldName]) !==
              getNestedValue(curValues, ['values', ...fieldName])
            }
            noStyle
          >
            {() => (
              <div>
                <Button
                  onClick={() =>
                    openJsonModal(fieldName, param.name || param.fieldName, 'object')
                  }
                  style={{ marginBottom: '10px' }}
                >
                  {`Edit ${param.name || param.fieldName} (JSON)`}
                </Button>
                {stringValue && (
                  <ReactAce
                    key={jsonEditorKey}
                    mode="json"
                    theme="github"
                    name={`readonly_json_${fieldName.join('_')}`}
                    value={stringValue}
                    readOnly
                    fontSize={14}
                    showPrintMargin
                    showGutter
                    highlightActiveLine={false}
                    setOptions={{
                      showLineNumbers: true,
                      tabSize: 2,
                      useWorker: false,
                    }}
                    style={{
                      width: '90%',
                      height: '200px',
                      borderRadius: '8px',
                      border: '1px solid #d9d9d9',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                    }}
                  />
                )}
              </div>
            )}
          </Form.Item>
        </Form.Item>
      );
    }

    // Render a list of fields for the object schema
    return (
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          marginBottom: '10px',
          marginLeft: '24px',
        }}
        key={fieldName.join('-')}
      >
        {schema.map((childParam) => {
          return renderField(childParam, [...fieldName, childParam.name || childParam.fieldName], true);
        })}
      </div>
    );
  };

  const openJsonModal = (fieldName, fieldLabel, dataType) => {
    const currentValue =
      getNestedValue(form.getFieldsValue(), ['values', ...fieldName]) || '';
    const stringValue =
      typeof currentValue === 'object' ? JSON.stringify(currentValue, null, 2) : '';
    setJsonValue(stringValue || '{}');
    setJsonModal({ visible: true, fieldName, dataType });
    setJsonFieldLabel(fieldLabel);
  };

  const handleJsonOk = () => {
    try {
      const parsedValue = JSON.parse(jsonValue);
      const fieldPath = ['values', ...jsonModal.fieldName];

      const fieldsValue = form.getFieldsValue();

      setNestedValue(fieldsValue, fieldPath, parsedValue);

      form.setFieldsValue(fieldsValue);

      // Increment the key to force re-render of Ace editor
      setJsonEditorKey((prevKey) => prevKey + 1);

      setJsonModal({ visible: false, fieldName: [], dataType: '' });
      setJsonValue('{}');
    } catch (error) {
      message.error('Invalid JSON format. Please correct it and try again.');
    }
  };

  const handleJsonCancel = () => {
    setJsonModal({ visible: false, fieldName: [], dataType: '' });
    setJsonValue('{}');
  };

  const renderFormDataFields = (param, fieldPath) => {
    if (!param.formDataFields) {
      return null;
    }

    const fieldName = [...fieldPath];

    return (
      <div key={fieldName.join('-')}>
        <Divider orientation="left">{param.name} (form-data)</Divider>

        {param.formDataFields.map((field) => {
          const fieldLabel = (
            <span>
              {field.fieldName}{' '}
              {field.description && (
                <Tooltip
                  title={field.description}
                  placement="right"
                  overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                  color="#f5f5f5"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )}{' '}
              <Text type="secondary">({field.fieldType})</Text>
            </span>
          );

          const fieldKey = [...fieldName, field.fieldName].join('-');
          const fieldCheckboxPath = ['include', ...fieldName, field.fieldName];
          const fieldValuePath = ['values', ...fieldName, field.fieldName];

          return (
            <div key={fieldKey} style={{ marginBottom: '16px' }}>
              <Space align="baseline">
                <Form.Item
                  name={fieldCheckboxPath}
                  valuePropName="checked"
                  initialValue={false}
                  noStyle
                >
                  <Checkbox />
                </Form.Item>
                <label>{fieldLabel}</label>
              </Space>

              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  getNestedValue(prevValues, fieldCheckboxPath) !==
                  getNestedValue(curValues, fieldCheckboxPath)
                }
                noStyle
              >
                {() => {
                  const includeField = getNestedValue(
                    form.getFieldsValue(),
                    fieldCheckboxPath
                  );
                  if (includeField) {
                    return (
                      <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                        {field.fieldType === 'file' ? (
                          <Form.Item
                            name={fieldValuePath}
                            valuePropName="fileList"
                            getValueFromEvent={(e) =>
                              Array.isArray(e) ? e : e && e.fileList
                            }
                            key={fieldKey}
                          >
                            <Upload beforeUpload={() => false}>
                              <Button icon={<UploadOutlined />}>
                                Upload {field.fieldName}
                              </Button>
                            </Upload>
                          </Form.Item>
                        ) : (
                          <Form.Item name={fieldValuePath} key={fieldKey}>
                            <Input placeholder={`Enter ${field.fieldName}`} />
                          </Form.Item>
                        )}
                      </div>
                    );
                  }
                  return null;
                }}
              </Form.Item>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFormFields = () => {
    if (!selectedApi || !selectedApi.parameters) {
      return null;
    }

    // Assign field paths to parameters for easier access during data submission
    const parametersWithPaths = selectedApi.parameters.map((param) => ({
      ...param,
      fieldPath: [param.parameterType, param.name],
      key: `${param.parameterType}_${param.name}`,
    }));

    return (
      <>
        {parametersWithPaths.map((param, index) => {
          // Conditional Divider for each parameter type group
          const isFirstOfType =
            parametersWithPaths.findIndex(
              (p) => p.parameterType === param.parameterType
            ) === index;

          const isBodyParameter = param.parameterType === 'body';

          return (
            <div key={param.key}>
              {isFirstOfType && (
                <Divider orientation="left">
                  {param.parameterType.charAt(0).toUpperCase() +
                    param.parameterType.slice(1)}{' '}
                  Parameters{' '}
                  {isBodyParameter && (
                    <span style={{ marginLeft: '260px' }}>
                       <Text >Use JSON Editor Instead?</Text>{' '}
                      <Switch
                      style={{marginLeft:'10px'}}
                        checked={useRawBody}
                        onChange={(checked) => setUseRawBody(checked)}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                     
                  
                    </span>
                  )}
                </Divider>
              )}
              {param.parameterType !== 'form-data'
                ? isBodyParameter && useRawBody
                  ? null // Skip rendering individual body parameters when using raw body
                  : renderField(param, param.fieldPath)
                : renderFormDataFields(param, param.fieldPath)}
            </div>
          );
        })}
        {/* Render Raw Body JSON Editor */}
        {useRawBody && (
  <div style={{ marginBottom: '16px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text strong>JSON Body</Text>
      <div>
        <Button
        type='link'
          icon={<CopyOutlined />}
          size="small"
          onClick={handleCopy}
        >
          Copy
        </Button>
        <Button
          icon={
            isEditorExpanded ? (
              <CompressOutlined />
            ) : (
              <ExpandOutlined />
            )
          }
          type='text'
          size="medium"
          onClick={toggleExpand}
          style={{ marginLeft: '8px' }}
        >
          {isEditorExpanded ? 'Collapse' : 'Expand'}
        </Button>
      </div>
    </div>
    <ReactAce
      mode="json"
      theme="cobalt"
      name="raw_body_json_editor"
      onChange={(value) => setRawBodyValue(value)}
      fontSize={14}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={rawBodyValue}
      setOptions={{
        showLineNumbers: true,
        tabSize: 2,
        useWorker: false,
      }}
      style={{
        width: '100%',
        height: isEditorExpanded ? '800px' : '400px',
        marginTop: '10px',
        borderRadius: '8px',
        border: '1px solid #d9d9d9',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      }}
    />
  </div>
)}

      </>
    );
  };

const renderAttributes = () => {
  if (useRawBody || !selectedApi?.attributes || selectedApi.attributes.length === 0) {
    return null;
  }




    return (
      <>
        <Divider orientation="left">
          Request Additional Attributes{' '}
          <Tooltip placement='right' title="Additional data fields or related entities you can request in the API response—specified in an array like attributes: ['screenshots', 'meta', 'tags']—to obtain more detailed information beyond the default minimal data.">
  <InfoCircleOutlined />
</Tooltip>

        </Divider>
        {selectedApi.attributes.map((attr, index) => {
          const attrFieldPath = ['attributes', `attr_${index}`];
          const label = (
            <span>
              {attr.name}{' '}
              {attr.description && (
                <Tooltip
                  title={attr.description}
                  placement="right"
                  overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                  color="#f5f5f5"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )}{' '}
              <Text type="secondary">({attr.responseType})</Text>
            </span>
          );
          return (
            <Form.Item
              key={attrFieldPath.join('-')}
              name={attrFieldPath}
              valuePropName="checked"
              initialValue={false}
              style={{ marginBottom: 8 }}
            >
              <Checkbox>{label}</Checkbox>
            </Form.Item>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Form form={form} layout="vertical">
        {renderFormFields()}
        {renderAttributes()}

        <Form.Item>
          <Button
            type="default"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={handlePreviewRequest}
            disabled={isLoading}
          >
            Preview Request
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={handleRunRequest}
            loading={isLoading}
            disabled={isLoading}
          >
            Run Request
          </Button>
        </Form.Item>
      </Form>

      {/* JSON Editor Modal */}
      <Modal
        title={`Edit ${jsonFieldLabel}`}
        visible={jsonModal.visible}
        onOk={handleJsonOk}
        onCancel={handleJsonCancel}
        width={800}
      >
        <ReactAce
          mode="json"
          theme="cobalt"
          name="json_editor"
          onChange={(value) => setJsonValue(value)}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          value={jsonValue}
          setOptions={{
            showLineNumbers: true,
            tabSize: 2,
            useWorker: false,
          }}
          style={{ width: '100%', height: '600px' }}
        />
      </Modal>
    </>
  );
}

export default ApiForm;
